import styled, { css } from 'styled-components';
import FlexBox from '@app/components/atoms/FlexBox';
import Text from '@app/components/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@app/components/atoms';

export const Container = styled(FlexBox)`
  position: relative;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 50px;
  @media screen and (max-width: 800px) {
    margin-top: 0;
    padding: 0 16px;
  }
`;

export const Content = styled(FlexBox)`
  height: 100%;
  box-sizing: border-box;
  position: relative;
  margin: 20px auto 0;
  min-height: 70vh;

  @media screen and (max-width: 800px) {
    max-width: 100%;
    margin-top: 10px;
  }
`;

export const LoginContainer = styled(FlexBox)`
  height: 100%;
  border-radius: 5px;
  min-height: 400px;
  display: flex;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 1000px) {
    border-radius: 0;
    box-shadow: none;
  }
`;

export const LoginLeft = styled(FlexBox)`
  min-width: 525px;
  min-height: 575px;
  width: 525px;
  height: 100%;
  padding: 65px 36px 40px 40px;
  box-sizing: border-box;
  overflow: auto;
  background: #fff;
  border-right: none;
  border-radius: 5px 0 0 5px;

  @media screen and (max-height: 800px) {
    padding: 40px 36px 30px 40px;
  }

  @media screen and (max-width: 1000px) {
    min-width: 90%;
    width: 100%;
    border-radius: 0;
    background: unset;
    padding: 24px 0;
  }
`;

export const LoginRight = styled(FlexBox)`
  min-height: 575px;
  height: 100%;
  background: linear-gradient(180deg, #2faf92 0%, #2f849a 100%);
  padding: 65px 50px 0 50px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  border-radius: 0 5px 5px 0;
  margin-left: -1px; /* This ensures no gap between sections */

  @media screen and (max-height: 800px) {
    padding: 40px 39px 0 39px;
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const Title = styled(Text)`
  font-weight: 800;
  font-size: 45px;
  line-height: 47px;
  color: #4f4f4f;
  margin-bottom: 10px;

  @media screen and (max-height: 800px) {
    font-size: 36px;
    line-height: 38px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1000px) {
    font-size: 35px;
    line-height: 37px;
    max-width: 450px;
  }
`;

export const SubTitle = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #888888;
  margin-bottom: 10px;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const InputWrapper = styled.div<{ inputType?: string }>`
  width: ${({ inputType }) => (inputType === 'email' ? '340px' : '250px')};
  margin: 10px 0;

  @media screen and (max-width: 800px) {
    width: 100%;
    max-width: 400px;
  }
`;

export const ContineuButtonContainer = styled(FlexBox)`
  @media screen and (max-width: 800px) {
    width: 100%;
    max-width: 400px;
  }
`;

export const ContinueButton = styled(Button)`
  width: 340px;

  @media screen and (max-width: 800px) {
    width: 100%;
    max-width: 400px;
  }
`;

export const SignUpText = styled(Text)`
  font-weight: 300;
  font-size: 16px;
  color: #888888;
  margin-top: 24px;

  span {
    font-weight: 700;
    color: #2faf92;
    cursor: pointer;
  }
`;

export const SignUpSubText = styled(Text)`
  font-weight: 300;
  font-size: 16px;
  color: #888888;
  margin-top: 10px;
  font-style: italic;
`;

export const RightTitle = styled(Text)`
  font-family: Inter;
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 32px;

  @media screen and (max-height: 800px) {
    font-size: 24px;
    margin-bottom: 24px;
  }
`;

export const RightItemContainer = styled(FlexBox)`
  width: 100%;
  gap: 12px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;

  @media screen and (max-height: 800px) {
    gap: 8px;
  }
`;

export const ImageContainer = styled.div``;
